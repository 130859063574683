import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

const MAX_IMAGE_PROMPT_COUNT = 5;

const DEFAULT_SETTINGS = {
    Mode: "fast",
    Version: "5",
    Style: "250",
    Quality: "1",
    Ar: "1:1",
    Stop: "100",
};

export const usePromptStore = create(
    persist(
        (set) => ({
            images: [],
            locked: false,
            settings: DEFAULT_SETTINGS,
            clearImages: () => set({ images: [] }),
            setSettings: (k, v) =>
                set((state) => ({ settings: { ...state.settings, [k]: v } })),
            triggerLock: () => set((state) => ({ locked: !state.locked })),
            resetSettings: () => set({ settings: DEFAULT_SETTINGS }),
            addOrRemoveImage: (obj) => set(addOrRemoveImage(obj)),
        }),
        {
            name: "user-prompt", // unique name
            storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
        }
    )
);

const addOrRemoveImage = (obj) => (state) => {
    const { ImageKey } = obj;
    // Make a copy of the original array
    const images = [...state.images];

    // Find the index of the object with the same hash
    const index = images.findIndex((item) => item.ImageKey === ImageKey);

    // If the object is already in the list, remove it
    if (index !== -1) {
        images.splice(index, 1);
    } else {
        // If the list is already at its maximum length, remove the first item
        if (images.length === MAX_IMAGE_PROMPT_COUNT) {
            images.shift();
        }

        // Add the new object to the end of the list
        images.push(obj);
    }

    return { images };
};
