import React, { Fragment, useEffect, useState } from "react";
import {
    BookOpenIcon,
    ShoppingCartIcon,
    HomeIcon,
    LinkIcon,
    MoonIcon,
    PaintBrushIcon,
    SunIcon,
    UserGroupIcon,
} from "@heroicons/react/24/outline";
import { classnames } from "tailwindcss-classnames";
import { Popover, Transition } from "@headlessui/react";
import { usePopper } from "react-popper";

import userAvatar from "../../user-128.png";
import UserCard from "../UserCard";
import { toast } from "react-toastify";
import { Link, useLocation, useRoute } from "wouter";
import PreserveQueryLink from "../link/PreserveQueryLink";
import { useUserStore } from "../../store/user";
import { Tooltip } from "react-tooltip";

function UserInfo() {
    let [referenceElement, setReferenceElement] = useState();
    let [popperElement, setPopperElement] = useState();
    let { styles, attributes } = usePopper(referenceElement, popperElement);

    return (
        <Popover className="relative">
            <Popover.Button
                className="focus:outline-none"
                ref={setReferenceElement}
            >
                <img
                    className="h-10 w-10 rounded-xl bg-white p-1"
                    src={userAvatar}
                ></img>
            </Popover.Button>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="scale-0 translate-y-1"
                enterTo="scale-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="scale-100 translate-y-0"
                leaveTo="scale-0 translate-y-1"
            >
                <Popover.Panel
                    ref={setPopperElement}
                    style={styles.popper}
                    {...attributes.popper}
                    className="absolute z-10 m-2 w-72 rounded-lg bg-light-primary p-2 shadow-lg dark:bg-black-primary"
                >
                    <UserCard />
                </Popover.Panel>
            </Transition>
        </Popover>
    );
}

const buttonActived = classnames("bg-green-600", "text-white", "rounded-xl");
const button = (active) =>
    classnames(
        "block",
        "p-3",
        "cursor-pointer",
        "hover:bg-green-600",
        "dark:hover:bg-green-600",
        "hover:text-white",
        "hover:rounded-xl",
        {
            "bg-light-primary dark:bg-black-primary rounded-full text-green-600":
                !active,
        },
        {
            [buttonActived]: active,
        }
    );

const ActiveLink = (props) => {
    const [isActive] = useRoute(props.href);
    const id = `active-link-${props.name}`;
    return (
        <PreserveQueryLink {...props}>
            <p>
                <a id={id} className={button(isActive)}>
                    {props.children}
                </a>
                <Tooltip
                    anchorId={id}
                    content={props.name}
                    variant="dark"
                    place="left"
                />
            </p>
        </PreserveQueryLink>
    );
};

const MODE_LIGHT = false;
const MODE_DARK = true;

const DarkMode = () => {
    const [mode, setMode] = useState(
        window.matchMedia("(prefers-color-scheme: dark)").matches
    );

    useEffect(() => {
        const handleColorSchemeChange = (event) => {
            if (event.matches) {
                setMode(MODE_DARK);
            } else {
                setMode(MODE_LIGHT);
            }
        };

        const colorSchemeQuery = window.matchMedia(
            "(prefers-color-scheme: dark)"
        );
        colorSchemeQuery.addEventListener("change", handleColorSchemeChange);

        // Set initial color scheme based on the user's preference
        handleColorSchemeChange(colorSchemeQuery);

        return () => {
            colorSchemeQuery.removeEventListener(
                "change",
                handleColorSchemeChange
            );
        };
    }, []);

    useEffect(() => {
        if (mode === MODE_DARK) {
            document.documentElement.classList.add("dark");
        } else {
            document.documentElement.classList.remove("dark");
        }
    }, [mode]);

    const handleSwitch = () => {
        setMode(!mode);
    };

    return (
        <a className={button(false)} onClick={handleSwitch}>
            {mode === MODE_LIGHT ? (
                <SunIcon className="h-5 w-5" fill="none" />
            ) : (
                <MoonIcon className="h-5 w-5" fill="none" />
            )}
        </a>
    );
};

export function ExternalLink(props) {
    const id = `active-link-${props.name}`;

    return (
        <p>
            <a
                id={id}
                className={button(false)}
                href={props.href}
                target="__blank"
            >
                {props.children}
            </a>
            <Tooltip
                anchorId={id}
                content={props.name}
                variant="dark"
                place="left"
            />
        </p>
    );
}

export default function SideBar() {
    const { isAdmin, fetchSession } = useUserStore();

    useEffect(() => {
        fetchSession();
        const i = setInterval(fetchSession, 5000);
        return () => clearInterval(i);
    }, []);

    const sibeItems = [
        {
            name: "操作台",
            href: "/",
            icon: <PaintBrushIcon className="h-5 w-5" fill="none" />,
        },

        {
            name: "淘宝店",
            href: "https://shop286855392.taobao.com/",
            icon: <ShoppingCartIcon className="h-5 w-5" fill="none" />,
            external: true,
        },

        {
            name: "Home",
            href: "/gallery",
            icon: <HomeIcon className="h-5 w-5" fill="none" />,
            hidden: true,
        },

        {
            name: "学会prompt",
            href: "https://learningprompt.wiki/docs/midjourney/mj-tutorial-extras/midjourney-extras-parameters",
            icon: <BookOpenIcon className="h-5 w-5" fill="none" />,
            external: true,
        },

        {
            name: "杂七杂八",
            href: "https://shb783vz13.feishu.cn/docx/P0AvdSOfgoh2DOxklodcVJG3nzh",
            icon: <LinkIcon className="h-5 w-5" fill="none" />,
            external: true,
        },

        {
            name: "Session管理",
            href: "/admin/sessions",
            icon: <UserGroupIcon className="h-5 w-5" fill="none" />,
            hidden: !isAdmin,
        },
    ];

    return (
        <div className="h-screen w-20 select-none p-2.5">
            <div className="flex h-full flex-col items-center space-y-4 rounded bg-light-third p-2 shadow-lg dark:bg-black-third">
                <p className="text-xl font-bold text-black dark:text-white">
                    MJ
                </p>
                <ul className="grow list-none space-y-3">
                    {sibeItems &&
                        sibeItems
                            .filter((item) => !item.hidden)
                            .map((item) => {
                                if (item.external) {
                                    return (
                                        <ExternalLink
                                            key={item.name}
                                            name={item.name}
                                            href={item.href}
                                        >
                                            {" "}
                                            <li>{item.icon}</li>
                                        </ExternalLink>
                                    );
                                }
                                return (
                                    <ActiveLink
                                        key={item.name}
                                        name={item.name}
                                        href={item.href}
                                    >
                                        <li>{item.icon}</li>
                                    </ActiveLink>
                                );
                            })}
                </ul>

                <DarkMode />
                <UserInfo />
            </div>
        </div>
    );
}
