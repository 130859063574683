// PreserveQueryLink.js
import React from "react";
import { Link, useLocation } from "wouter";

const PreserveQueryLink = ({ href, children, ...props }) => {
    // 获取当前 URL 的查询字符串
    const queryString = window.location.search;

    // 将查询字符串添加到新的链接
    // bug,如果href中本来就还有query，将会出现问题
    const newHref = `${href}${queryString ? `${queryString}` : ""}`;

    return (
        <Link href={newHref} {...props}>
            {children}
        </Link>
    );
};

export default PreserveQueryLink;
