import React, { useMemo } from "react";
import ImageSkeleton from "../../../ImageSkeleton";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

export default function DescribeMessage(props) {
    const {
        message: {
            id,
            task: {
                interaction: {
                    type,
                    describe: { file_url },
                },
            },
            response,
            status,
            Message,
        },
    } = props;

    const lines = useMemo(() => {
        const text = response?.describe?.raw_text || "";
        return text.split("\n");
    }, [response]);

    return (
        <>
            <div className="flex flex-col space-y-1 text-sm font-medium text-slate-700 dark:text-white md:text-lg">
                {lines.map((line, i) => (
                    <ReactMarkdown key={i}>{line}</ReactMarkdown>
                ))}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-4">
                <ImageSkeleton className="rounded" src={file_url} />
            </div>
        </>
    );
}
