import React from "react";
import { Menu } from "@headlessui/react";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/solid";
import logo from "../../../../Midjourney-logo.png";
import userLogo from "../../../../user-128.png";
import {
    CheckCircleIcon,
    XCircleIcon,
    ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import {
    INTERACTION_TYPE_IMAGINE,
    INTERACTION_TYPE_REROLL,
    INTERACTION_TYPE_VARY,
    INTERACTION_TYPE_UPSCALE,
    INTERACTION_TYPE_DESCRIBE,
    getInteractionName,
} from "../../../../store/command";
import ImagineMessage from "../ImagineMessage";
import DescribeMessage from "../DescribeMessage";
import classnames from "tailwindcss-classnames";
import UpscaleMessage from "../UpscaleMessage";
import RedoMessage from "../RedoMessage";
import VaryMessage from "../VaryMessage";

function MessageDropdown(props) {
    const buttons = [
        {
            name: "reroll",
            onClick: () => {},
        },
        {
            name: "u1",
            onClick: () => {},
        },
    ];

    return (
        <>
            <Menu as="div" className="relative inline-block h-4 w-4 text-left">
                <Menu.Button className="inline-flex w-full justify-center rounded-md text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 hover:bg-opacity-30">
                    <EllipsisHorizontalIcon className="h-4 w-4 text-white" />
                </Menu.Button>
                <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-gray-100 overflow-hidden rounded-md bg-light-primary shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-black-primary">
                    {buttons.map((btn) => (
                        <Menu.Item key={btn.name}>
                            {({ active }) => (
                                <button
                                    className="group flex w-full items-center px-2 py-2 text-sm text-slate-900 hover:bg-sky-500 dark:text-white"
                                    onClick={btn.onClick}
                                >
                                    {btn.name}
                                </button>
                            )}
                        </Menu.Item>
                    ))}
                </Menu.Items>
            </Menu>
        </>
    );
}

const StatusReceived = 0;
const StatusSubmited = 2;
const StatusPending = 3;
const StatusFailed = 1;
const StatusFinished = 4;

export default function Message(props) {
    const {
        message: {
            id,
            Role = "Midjourney",
            task: {
                interaction: { type },
            },
            response,
            status,
            status_human,
        },
    } = props;

    const taskError = response?.error;

    const getStatusIcon = (status) => {
        switch (status) {
            case StatusFailed:
                return (
                    <XCircleIcon className=" h-5 w-5 shrink-0 stroke-red-600" />
                );
            case StatusReceived:
                return (
                    <ExclamationCircleIcon
                        fill="none"
                        className=" h-5 w-5 shrink-0 stroke-yellow-600"
                    />
                );
            default:
                return (
                    <CheckCircleIcon className=" h-5 w-5 shrink-0 stroke-green-600" />
                );
        }
    };

    const messageBody = () => {
        switch (type) {
            case INTERACTION_TYPE_IMAGINE:
                return <ImagineMessage {...props} />;
            case INTERACTION_TYPE_REROLL:
                return <RedoMessage {...props} />;
            case INTERACTION_TYPE_UPSCALE:
                return <UpscaleMessage {...props} />;
            case INTERACTION_TYPE_VARY:
                return <VaryMessage {...props} />;
            case INTERACTION_TYPE_DESCRIBE:
                return <DescribeMessage {...props} />;
            default:
                break;
        }
    };

    const MessageBoxStyle = (isUser) =>
        classnames(
            {
                "border-user-message bg-user-message": isUser,
                "border-bot-message bg-bot-message": !isUser,
            },
            "border-l-[3px] flex space-x-4 p-2 bg-opacity-10 hover:bg-opacity-5 dark:hover:bg-opacity-5"
        );

    return (
        <div className={MessageBoxStyle(Role === "User")}>
            {Role === "User" ? (
                <img
                    className="h-10 w-10 rounded-full bg-white p-1"
                    src={userLogo}
                />
            ) : (
                <img className="h-10 w-10 rounded-full bg-white" src={logo} />
            )}

            <div className="shadow-m flex w-full flex-col space-y-4 rounded">
                <p className="text-transform: grow text-lg capitalize text-blue-500">
                    {getInteractionName(type)}
                </p>

                {messageBody()}

                <div className="flex items-center space-x-2 font-medium">
                    {getStatusIcon(status)}
                    <p className="text-sm text-slate-500">
                        {status_human}
                        {taskError && <span>：{taskError.title} </span>}
                    </p>
                </div>

                <p className="text-sm text-slate-400">
                    {taskError && taskError.description}
                </p>
            </div>
        </div>
    );
}
