import { create } from "zustand";
import {
    fetchCommandHistory,
    sendDescribeCommand,
    sendImagineCommand,
    sendRedoCommand,
    sendUpscaleCommand,
    sendVaryCommand,
} from "../api/commandApi";

export const INTERACTION_TYPE_IMAGINE = 1;
export const INTERACTION_TYPE_REROLL = 4;
export const INTERACTION_TYPE_VARY = 3;
export const INTERACTION_TYPE_UPSCALE = 2;
export const INTERACTION_TYPE_DESCRIBE = 7;

export const getInteractionName = (ty) => {
    switch (ty) {
        case INTERACTION_TYPE_IMAGINE:
            return "图片想象";
        case INTERACTION_TYPE_REROLL:
            return "图片重画";
        case INTERACTION_TYPE_VARY:
            return "图片微调";
        case INTERACTION_TYPE_UPSCALE:
            return "图片放大";
        case INTERACTION_TYPE_DESCRIBE:
            return "图片描述";
        default:
            break;
    }
};

export const useCommandStore = create((set, get) => ({
    history: [],
    updatedAt: "",

    fetchHistory: async () => {
        let history = await fetchCommandHistory();
        let updatedAt = "";
        if (history && history.length > 0) {
            updatedAt = history[history.length - 1].updated_at;
        }
        set({ history, updatedAt });
    },

    appendHistory: (msg) => {
        console.log(msg);
        const history = get().history;
        history.push(msg);
        const updatedAt = msg.updated_at;
        set({ history, updatedAt });
    },

    sendDescribe: async (image) => {
        const command = {
            file_url: image.Image.Url,
        };

        await sendDescribeCommand(command);
    },

    sendBlend: async (images) => {
        const urls = images.map((image) => image.Image.Url).join(" ");

        const result = urls.trim();
        const prompt = `${result} --ar 1:1`;

        await sendImagineCommand({
            prompt: prompt,
            niji: false,
        });
    },

    sendImagine: async (text, images, settings) => {
        let prompt = text;

        if (images && images.length > 0) {
            const urls = images.map((image) => image.Image.Url).join(" ");
            const result = urls.trim();
            if (result.length > 0) {
                prompt = `${result} ${prompt}`;
            }
        }

        await sendImagineCommand({
            prompt: prompt,
            niji: false,
            Settings: settings,
        });
    },

    sendRedo: async (message, prompt) => {
        const MessageId = message?.id;
        await sendRedoCommand({
            task_id: MessageId,
            prompt: prompt,
        });
    },

    sendVary: async (message, index, prompt) => {
        const MessageId = message?.id;
        await sendVaryCommand({
            task_id: MessageId,
            index: index,
            prompt,
        });
    },

    sendUpscale: async (message, index) => {
        const MessageId = message?.id;
        await sendUpscaleCommand({
            task_id: MessageId,
            index: index,
        });
    },
}));
