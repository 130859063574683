import axios from "axios";
import { toast } from "react-toastify";

const urlParams = new URLSearchParams(window.location.search);
const code = urlParams.get("code");

const axiosInstance = axios.create({
    baseURL: "https://midjourney-v3.wisburg.com/api",
    // baseURL: "http://127.0.0.1:8089/api",
    headers: {
        Authorization: code,
    },
});

axiosInstance.interceptors.response.use(
    (response) => {
        const data = response.data;
        if (data.status === 1) {
            toast.error(data.message);
            return Promise.reject(data.message);
        }

        return data;
    },
    (error) => {
        if (error.response && error?.response?.status !== 403) {
            toast.error(error.response.data);
            return Promise.reject(error.response.data);
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
