import { CheckCircleIcon, CheckIcon } from "@heroicons/react/24/outline";
import React from "react";

const PLANS = [
    {
        name: "基础会员",
        amount: "15",
        duration: "月",
        privileges: [
            {
                name: "无限出图",
            },
        ],
    },
    {
        name: "标准会员",
        amount: "25",
        duration: "月",
        privileges: [
            {
                name: "1.5小时快速出图时间",
            },
            {
                name: "无限出图",
            },
        ],
    },
    {
        name: "高级会员",
        amount: "50",
        duration: "月",
        privileges: [
            {
                name: "3小时快速出图时间",
            },
            {
                name: "无限出图",
            },
        ],
    },
];

function PlanItem({ name, amount, duration, privileges = [], ...props }) {
    return (
        <div className="flex h-full flex-col space-y-8 rounded-lg bg-light-5 p-10">
            <h3 className="text-2xl font-bold text-pink-400">{name}</h3>
            <p>
                <span className="mr-3 text-5xl text-white">¥{amount}</span>
                <span className="text-gray-400">/{duration}</span>
            </p>
            <button className="rounded bg-sky-500 p-4 text-sm text-white hover:bg-sky-600">
                立即订阅
            </button>

            <ul className=" divide-y-[1px] pt-12 text-left text-sm font-medium">
                {privileges.map((p) => (
                    <li
                        className="flex items-center py-4 text-white"
                        key={p.name}
                    >
                        <CheckIcon className="mr-2 h-4 w-4 text-green-500" />
                        {p.name}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default function SubscriptionPage(props) {
    return (
        <div className="bg-light-primary">
            <div className="m-auto flex flex-wrap justify-center p-8">
                {PLANS.map((plan) => (
                    <div className="min-w-[350px] p-4">
                        <PlanItem key={plan.name} {...plan} />
                    </div>
                ))}
            </div>
        </div>
    );
}
