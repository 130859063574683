import "./App.css";
import Midjourney from "./pages/Midjourney";
import { ToastContainer, toast } from "react-toastify";

import "photoswipe/dist/photoswipe.css";
import "react-toastify/dist/ReactToastify.css";
import "react-tooltip/dist/react-tooltip.css";

import { Route, Router, Switch, useLocation, useRouter } from "wouter";
import Home from "./pages/Home";
import DashboardLayout from "./components/layout/DashboardLayout";
import BotsPage from "./pages/BotsPage";
import SessionPage from "./pages/SessionPage";
import SubscriptionPage from "./pages/SubscriptionPage";
import ImageCrop from "./components/ImageCrop";

const NestedRoutes = (props) => {
    const router = useRouter();
    const [parentLocation] = useLocation();

    const nestedBase = `${router.base}${props.base}`;

    // don't render anything outside of the scope
    if (!parentLocation.startsWith(nestedBase)) return null;

    // we need key to make sure the router will remount when base changed
    return (
        <Router base={nestedBase} key={nestedBase}>
            {props.children}
        </Router>
    );
};

function App() {
    return (
        <div className="mx-auto">
            <Switch>
                <Route path="/gallery" component={Home}></Route>
                <Route
                    path="/subscription"
                    component={SubscriptionPage}
                ></Route>
                <DashboardLayout>
                    <Route path="/" component={Midjourney} />
                    <Route path="/crop" component={ImageCrop}></Route>
                    <NestedRoutes base="/admin">
                        <Route path="/bots" component={BotsPage} />
                        <Route path="/sessions" component={SessionPage} />
                    </NestedRoutes>
                </DashboardLayout>
            </Switch>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    );
}

export default App;
