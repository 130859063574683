import React, { useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import ImageCrop from "../../../ImageCrop";
import { removeImageBg } from "../../../../api/imageApi";
import { useImagesStore } from "../../../../store/images";
import { ArrowPathIcon } from "@heroicons/react/24/solid";

export default function RemoveBgDialog({
    open,
    imgSrc = "",
    imgHeight = 0,
    imgWidth = 0,
    onOpenChange = () => {},
    onCancel = () => {},
}) {
    const { refreshImages } = useImagesStore();

    const handleCancel = () => {
        onCancel();
    };

    const handleCrop = async (crop) => {
        const height = Math.round((imgHeight * crop?.height) / 100);
        const width = Math.round((imgHeight * crop?.width) / 100);
        const x = Math.round((imgWidth * crop?.x) / 100);
        const y = Math.round((imgHeight * crop?.y) / 100);

        console.log(crop);

        const resp = await removeImageBg({
            url: imgSrc,
            height: imgHeight,
            width: imgWidth,
            crop,
        });
        console.log(resp);
        await refreshImages();
    };

    return (
        <Dialog.Root open={open} onOpenChange={onOpenChange}>
            <Dialog.Portal>
                <Dialog.Overlay className="fixed inset-0 bg-blackA9 data-[state=open]:animate-overlayShow" />
                <Dialog.Content className="fixed left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none data-[state=open]:animate-contentShow">
                    <Dialog.Title className="m-0 text-[17px] font-medium text-mauve12">
                        图片去除背景
                    </Dialog.Title>
                    <Dialog.Description className="mb-5 mt-[10px] text-[15px] leading-normal text-mauve11">
                        可根据需要去除图片背景
                    </Dialog.Description>

                    <div>
                        <ImageCrop imgSrc={imgSrc} onCrop={handleCrop} />
                    </div>

                    <div className="flex justify-end gap-[25px]">
                        <Dialog.Close asChild>
                            <button
                                onClick={handleCancel}
                                className="inline-flex h-[35px] items-center	 justify-center rounded-[4px] px-[15px] text-sm font-medium text-mauve11 outline-none hover:underline "
                            >
                                退出
                            </button>
                        </Dialog.Close>
                    </div>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
}
