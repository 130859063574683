import React, { useEffect } from "react";
import MessagesBox from "../MessagesBox";
import PromptInput from "../PromptInput";
import UserCard from "../UserCard";

export default function ChatBox() {
    return (
        <div className="flex h-screen grow basis-1/4 flex-col space-y-4 rounded py-2.5">
            <MessagesBox />
            <UserCard />
            <PromptInput />
        </div>
    );
}
