import React, { useEffect, useRef, useState } from "react";
import classnames from "tailwindcss-classnames";

export default React.forwardRef((props, ref) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    const handleImageLoad = () => {
        setIsLoading(false);
    };

    const handleImageError = () => {};

    const hidden = !isLoading && !isError;

    const Skeleton = classnames(
        "absolute inset-0 animate-pulse bg-gray-200 z-20",
        {
            hidden: hidden,
        }
    );

    const imgStyle = classnames(props.className, { invisible: !hidden });

    return (
        <div className="relative h-auto w-auto select-none">
            <div className={Skeleton}></div>
            <img
                ref={ref}
                {...props}
                className={imgStyle}
                onLoad={handleImageLoad}
                onError={handleImageError}
            />
        </div>
    );
});
