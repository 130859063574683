import { create } from "zustand";

export const ToolPanelWorkSpace = "workspace";
export const ToolPanelSettings = "settings";
export const ToolPanelImages = "images;";

export const useToolBarStore = create((set) => ({
    panel: ToolPanelImages,

    openPanel: (panel) => set({ panel }),
}));
