import axiosInstance from "./axiosInstance";

export const sendDescribeCommand = async (command) => {
    try {
        const response = await axiosInstance.post(
            "/interaction/describe",
            command
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const sendImagineCommand = async (command) => {
    try {
        const response = await axiosInstance.post(
            "/interaction/imagine",
            command
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const sendUpscaleCommand = async (command) => {
    try {
        const response = await axiosInstance.post(
            "/interaction/upscale",
            command
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const sendVaryCommand = async (command) => {
    try {
        const response = await axiosInstance.post("/interaction/vary", command);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const sendRedoCommand = async (command) => {
    try {
        const response = await axiosInstance.post("/interaction/redo", command);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const sendBlendCommand = async (command) => {
    try {
        const response = await axiosInstance.post(
            "/interaction/blend",
            command
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchCommandHistory = async () => {
    try {
        const response = await axiosInstance.get("/user/messages");
        return response.data.reverse();
    } catch (error) {
        throw error;
    }
};
