import axiosInstance from "./axiosInstance";

export const fetchAllBots = async () => {
    try {
        const response = await axiosInstance.get("/admin/bots");
        return response.data;
    } catch (error) {
        throw error;
    }
};

// params
// username - required
export const createBot = async (info) => {
    try {
        const response = await axiosInstance.post("/admin/bot/create", info);
        return response.data;
    } catch (error) {
        throw error;
    }
};

// params
// username - required
export const updateBot = async (info) => {
    try {
        const response = await axiosInstance.put("/admin/bot/update", info);
        return response.data;
    } catch (error) {
        throw error;
    }
};
