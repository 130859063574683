import { create } from "zustand";
import { fetchSession } from "../api/userApi";

export function formatDate(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
}

// 有效期内无限次使用
export const SUB_MODE_DURATION_INFINITE = 0;
// 有效期内有限次使用
export const SUB_MODE_DURATION_COUNT = 1;
// 永久有限次使用
export const SUB_MODE_COUNT = 2;

export const useUserStore = create((set) => ({
    sessionId: "",
    expiredAt: "",
    writeable: false,
    subMode: SUB_MODE_DURATION_INFINITE,
    count: 0,
    isAdmin: false,

    fetchSession: async () => {
        try {
            const session = await fetchSession();

            // 解析时间字符串
            const time = new Date(session.expired_at);

            return set({
                session: session.session_id,
                subMode: session.sub_mode,
                expiredAt: formatDate(time),
                count: session.count,
                isAdmin: session.is_admin,
                writeable: session.writeable,
            });
        } catch (e) {
            console.error(e);
            return set({ writeable: false });
        }
    },
}));
