import React, { useState } from "react";
import { useCommandStore } from "../../store/command";
import { LightBulbIcon } from "@heroicons/react/24/solid";
import {
    CursorArrowRaysIcon,
    PaperClipIcon,
} from "@heroicons/react/24/outline";

const examples = [
    {
        name: "示例：中国队世界杯夺冠",
        messages: [
            {
                ID: "1647617975001284608",
                Session: "1089829716183232612",
                Role: "User",
                CommandName: "imagine",
                JobID: "",
                Command: {
                    ID: "",
                    Command: "imagine",
                    Text: "chinese football team wins the world cup, they are celebrating",
                    Flags: "--v 5",
                    Prompt: "chinese football team wins the world cup, they are celebrating --v 5",
                    ImageIndex: 0,
                    ImageUUID: "",
                    ImageURL: "",
                    MessageID: "",
                },
                Status: 1,
                StatusHuman: "已提交至服务器，等待提交至Midjourney",
                Message: "已提交至服务器，等待提交至Midjourney",
                Error: "",
                Progress: 0,
                Images: [],
                Mode: "",
                AutoTranslate: false,
                CreatedAt: "2023-04-16T23:08:31.164+08:00",
                UpdatedAt: "2023-04-16T23:08:31.164+08:00",
            },
            {
                ID: "1097176716834783263",
                Session: "1089829716183232612",
                Role: "Midjourney",
                CommandName: "imagine",
                JobID: "",
                Command: {
                    ID: "1097176716834783263",
                    Command: "imagine",
                    Text: "chinese football team wins the world cup, they are celebrating",
                    Flags: "--seed 1317077026 --v 5",
                    Prompt: "chinese football team wins the world cup, they are celebrating --seed 1317077026 --v 5",
                    ImageIndex: 0,
                    ImageUUID: "",
                    ImageURL: "",
                    MessageID: "",
                },
                Status: 3,
                StatusHuman: "正在作图中，预计很快将会完成",
                Message: "正在作图中，预计很快将会完成",
                Error: "",
                Progress: 93,
                Images: [
                    {
                        MessageID: "1097176716834783263",
                        UUID: "",
                        Url: "https://cdn.discordapp.com/attachments/1087954944591667212/1097176981310799902/grid_0.webp",
                        ProxyUrl:
                            "http://rt6s1phwv.hd-bkt.clouddn.com/ac5badb0-0db2-4fa4-a34a-80cf877d5c0e",
                        ThumbnailUrl:
                            "https://pics-mj.ai3.design/ac5badb0-0db2-4fa4-a34a-80cf877d5c0e",
                        Type: "image/webp",
                        Width: 512,
                        Height: 512,
                        Size: 108996,
                        CreatedAt: "2023-04-16T23:09:08.873+08:00",
                        UpdatedAt: "2023-04-16T23:09:38.266+08:00",
                    },
                ],
                Mode: "",
                AutoTranslate: false,
                CreatedAt: "2023-04-16T23:08:34.391+08:00",
                UpdatedAt: "2023-04-16T23:09:38.259+08:00",
            },
            {
                ID: "1097177022217859082",
                Session: "1089829716183232612",
                Role: "Midjourney",
                CommandName: "imagine",
                JobID: "",
                Command: {
                    ID: "1097177022217859082",
                    Command: "imagine",
                    Text: "chinese football team wins the world cup, they are celebrating",
                    Flags: "--seed 1317077026 --v 5",
                    Prompt: "chinese football team wins the world cup, they are celebrating --seed 1317077026 --v 5",
                    ImageIndex: 0,
                    ImageUUID: "",
                    ImageURL: "",
                    MessageID: "",
                },
                Status: 4,
                StatusHuman: "任务已经完成，图片显示可能出现延迟",
                Message: "任务已经完成，图片显示可能出现延迟",
                Error: "",
                Progress: 100,
                Images: [
                    {
                        MessageID: "1097177022217859082",
                        UUID: "733c162a-811c-4cb1-9f8a-cf72d14f1c91",
                        Url: "https://cdn.discordapp.com/attachments/1087954944591667212/1097177021672607794/importcjj_chinese_football_team_wins_the_world_cup_they_are_cel_733c162a-811c-4cb1-9f8a-cf72d14f1c91.png",
                        ProxyUrl:
                            "http://rt6s1phwv.hd-bkt.clouddn.com/da1b5f97-3cde-4a4f-9e78-e68d109dc26f",
                        ThumbnailUrl:
                            "https://pics-mj.ai3.design/da1b5f97-3cde-4a4f-9e78-e68d109dc26f",
                        Type: "image/png",
                        Width: 2048,
                        Height: 2048,
                        Size: 5299688,
                        CreatedAt: "2023-04-16T23:09:50.215+08:00",
                        UpdatedAt: "2023-04-16T23:09:50.215+08:00",
                    },
                ],
                Mode: "",
                AutoTranslate: false,
                CreatedAt: "2023-04-16T23:09:50.209+08:00",
                UpdatedAt: "2023-04-16T23:09:50.209+08:00",
            },
        ],
    },
    {
        name: "示例：拜登90年代到中国街头参观",
        messages: [
            {
                ID: "1647625600879296512",
                Session: "1089829716183232612",
                Role: "User",
                CommandName: "imagine",
                JobID: "",
                Command: {
                    ID: "",
                    Command: "imagine",
                    Text: "a group of Chinese people around President Biden, on the street, fuji film, photography, style of 1990s",
                    Flags: "--v 5",
                    Prompt: "a group of Chinese people around President Biden, on the street, fuji film, photography, style of 1990s --v 5",
                    ImageIndex: 0,
                    ImageUUID: "",
                    ImageURL: "",
                    MessageID: "",
                },
                Status: 1,
                StatusHuman: "已提交至服务器，等待提交至Midjourney",
                Message: "已提交至服务器，等待提交至Midjourney",
                Error: "",
                Progress: 0,
                Images: [],
                Mode: "",
                AutoTranslate: false,
                CreatedAt: "2023-04-16T23:38:49.315+08:00",
                UpdatedAt: "2023-04-16T23:38:49.315+08:00",
            },
            {
                ID: "1097184334152212530",
                Session: "1089829716183232612",
                Role: "Midjourney",
                CommandName: "imagine",
                JobID: "",
                Command: {
                    ID: "1097184334152212530",
                    Command: "imagine",
                    Text: "a group of Chinese people around President Biden, on the street, fuji film, photography, style of 1990s",
                    Flags: "--seed 795303426 --v 5",
                    Prompt: "a group of Chinese people around President Biden, on the street, fuji film, photography, style of 1990s --seed 795303426 --v 5",
                    ImageIndex: 0,
                    ImageUUID: "",
                    ImageURL: "",
                    MessageID: "",
                },
                Status: 3,
                StatusHuman: "正在作图中，预计很快将会完成",
                Message: "正在作图中，预计很快将会完成",
                Error: "",
                Progress: 93,
                Images: [
                    {
                        MessageID: "1097184334152212530",
                        UUID: "",
                        Url: "https://cdn.discordapp.com/attachments/1087954944591667212/1097184604311535626/grid_0.webp",
                        ProxyUrl:
                            "http://rt6s1phwv.hd-bkt.clouddn.com/69755a9f-e388-408c-8669-59549ce8570b",
                        ThumbnailUrl:
                            "https://pics-mj.ai3.design/69755a9f-e388-408c-8669-59549ce8570b",
                        Type: "image/webp",
                        Width: 512,
                        Height: 512,
                        Size: 103172,
                        CreatedAt: "2023-04-16T23:39:26.215+08:00",
                        UpdatedAt: "2023-04-16T23:39:55.56+08:00",
                    },
                ],
                Mode: "",
                AutoTranslate: false,
                CreatedAt: "2023-04-16T23:38:50.426+08:00",
                UpdatedAt: "2023-04-16T23:39:55.554+08:00",
            },
            {
                ID: "1097184651598114968",
                Session: "1089829716183232612",
                Role: "Midjourney",
                CommandName: "imagine",
                JobID: "",
                Command: {
                    ID: "1097184651598114968",
                    Command: "imagine",
                    Text: "a group of Chinese people around President Biden, on the street, fuji film, photography, style of 1990s",
                    Flags: "--seed 795303426 --v 5",
                    Prompt: "a group of Chinese people around President Biden, on the street, fuji film, photography, style of 1990s --seed 795303426 --v 5",
                    ImageIndex: 0,
                    ImageUUID: "",
                    ImageURL: "",
                    MessageID: "",
                },
                Status: 4,
                StatusHuman: "任务已经完成，图片显示可能出现延迟",
                Message: "任务已经完成，图片显示可能出现延迟",
                Error: "",
                Progress: 100,
                Images: [
                    {
                        MessageID: "1097184651598114968",
                        UUID: "9a202288-06d5-4de2-891d-651dcb338123",
                        Url: "https://cdn.discordapp.com/attachments/1087954944591667212/1097184650826367016/importcjj_a_group_of_Chinese_people_around_President_Biden_on_t_9a202288-06d5-4de2-891d-651dcb338123.png",
                        ProxyUrl:
                            "http://rt6s1phwv.hd-bkt.clouddn.com/1940a826-534f-473a-ad90-2c24d9806a5c",
                        ThumbnailUrl:
                            "https://pics-mj.ai3.design/1940a826-534f-473a-ad90-2c24d9806a5c",
                        Type: "image/png",
                        Width: 2048,
                        Height: 2048,
                        Size: 6704589,
                        CreatedAt: "2023-04-16T23:40:09.391+08:00",
                        UpdatedAt: "2023-04-16T23:40:09.391+08:00",
                    },
                ],
                Mode: "",
                AutoTranslate: false,
                CreatedAt: "2023-04-16T23:40:09.385+08:00",
                UpdatedAt: "2023-04-16T23:40:09.385+08:00",
            },
        ],
    },
    {
        name: "图片融合示例：钢铁侠原来是John wick",
        messages: [
            {
                ID: "1647631294135271424",
                Session: "1089829716183232612",
                Role: "User",
                CommandName: "imagine",
                JobID: "",
                Command: {
                    ID: "",
                    Command: "imagine",
                    Text: "http://rt6s1phwv.hd-bkt.clouddn.com/cb47afac3117ea8c787250faa38a804b.jpg http://rt6s1phwv.hd-bkt.clouddn.com/d648665e6fc8eadce107c104dfd3952c.webp",
                    Flags: "--iw 2 --v 5",
                    Prompt: "http://rt6s1phwv.hd-bkt.clouddn.com/cb47afac3117ea8c787250faa38a804b.jpg http://rt6s1phwv.hd-bkt.clouddn.com/d648665e6fc8eadce107c104dfd3952c.webp --v 5 --iw 2",
                    ImageIndex: 0,
                    ImageUUID: "",
                    ImageURL: "",
                    MessageID: "",
                },
                Status: 1,
                StatusHuman: "已提交至服务器，等待提交至Midjourney",
                Message: "已提交至服务器，等待提交至Midjourney",
                Error: "",
                Progress: 0,
                Images: [],
                Mode: "",
                AutoTranslate: false,
                CreatedAt: "2023-04-17T00:01:26.693+08:00",
                UpdatedAt: "2023-04-17T00:01:26.693+08:00",
            },
            {
                ID: "1097190030641995827",
                Session: "1089829716183232612",
                Role: "Midjourney",
                CommandName: "imagine",
                JobID: "",
                Command: {
                    ID: "1097190030641995827",
                    Command: "imagine",
                    Text: "<https://s.mj.run/qbDp-JzabEg> <https://s.mj.run/lpwi9mTx2C4>",
                    Flags: "--iw 2 --seed 89146384 --v 5",
                    Prompt: "<https://s.mj.run/qbDp-JzabEg> <https://s.mj.run/lpwi9mTx2C4> --iw 2 --seed 89146384 --v 5",
                    ImageIndex: 0,
                    ImageUUID: "",
                    ImageURL: "",
                    MessageID: "",
                },
                Status: 3,
                StatusHuman: "正在作图中，预计很快将会完成",
                Message: "正在作图中，预计很快将会完成",
                Error: "",
                Progress: 93,
                Images: [
                    {
                        MessageID: "1097190030641995827",
                        UUID: "",
                        Url: "https://cdn.discordapp.com/attachments/1087954944591667212/1097190292492394516/grid_0.webp",
                        ProxyUrl:
                            "http://rt6s1phwv.hd-bkt.clouddn.com/5d0e4cbc-a7e7-4d62-8c85-ea21b815d276",
                        ThumbnailUrl:
                            "https://pics-mj.ai3.design/5d0e4cbc-a7e7-4d62-8c85-ea21b815d276",
                        Type: "image/webp",
                        Width: 512,
                        Height: 512,
                        Size: 41848,
                        CreatedAt: "2023-04-17T00:02:02.588+08:00",
                        UpdatedAt: "2023-04-17T00:02:31.472+08:00",
                    },
                ],
                Mode: "",
                AutoTranslate: false,
                CreatedAt: "2023-04-17T00:01:28.697+08:00",
                UpdatedAt: "2023-04-17T00:02:31.466+08:00",
            },
            {
                ID: "1097190329930743900",
                Session: "1089829716183232612",
                Role: "Midjourney",
                CommandName: "imagine",
                JobID: "",
                Command: {
                    ID: "1097190329930743900",
                    Command: "imagine",
                    Text: "<https://s.mj.run/qbDp-JzabEg> <https://s.mj.run/lpwi9mTx2C4>",
                    Flags: "--iw 2 --seed 89146384 --v 5",
                    Prompt: "<https://s.mj.run/qbDp-JzabEg> <https://s.mj.run/lpwi9mTx2C4> --iw 2 --seed 89146384 --v 5",
                    ImageIndex: 0,
                    ImageUUID: "",
                    ImageURL: "",
                    MessageID: "",
                },
                Status: 4,
                StatusHuman: "任务已经完成，图片显示可能出现延迟",
                Message: "任务已经完成，图片显示可能出现延迟",
                Error: "",
                Progress: 100,
                Images: [
                    {
                        MessageID: "1097190329930743900",
                        UUID: "826dae6e-ac21-4d2d-8117-5e93ff728f15",
                        Url: "https://cdn.discordapp.com/attachments/1087954944591667212/1097190329393893396/importcjj_None_826dae6e-ac21-4d2d-8117-5e93ff728f15.png",
                        ProxyUrl:
                            "http://rt6s1phwv.hd-bkt.clouddn.com/f5b00464-2340-4256-892c-8ec69ea14ff4",
                        ThumbnailUrl:
                            "https://pics-mj.ai3.design/f5b00464-2340-4256-892c-8ec69ea14ff4",
                        Type: "image/png",
                        Width: 2048,
                        Height: 2048,
                        Size: 3243877,
                        CreatedAt: "2023-04-17T00:02:42.269+08:00",
                        UpdatedAt: "2023-04-17T00:02:42.269+08:00",
                    },
                ],
                Mode: "",
                AutoTranslate: false,
                CreatedAt: "2023-04-17T00:02:42.263+08:00",
                UpdatedAt: "2023-04-17T00:02:42.263+08:00",
            },
        ],
    },
];

export function Example(props) {
    const { name, messages } = props;
    const [disable, setDisable] = useState(false);
    const appendHistory = useCommandStore((state) => state.appendHistory);

    const handleClick = () => {
        setDisable(true);
        for (let i in messages) {
            setTimeout(() => appendHistory(messages[i]), i * 1500);
        }
    };

    return (
        <>
            <button
                className="flex items-center space-x-1 rounded bg-blue-500 p-2 text-white disabled:bg-light-4"
                disabled={disable}
                onClick={handleClick}
            >
                <PaperClipIcon className="h-4 w-4" />
                <span>{name}</span>
            </button>
        </>
    );
}

export default function Examples() {
    return (
        <ul className="flex flex-wrap p-1 text-sm font-medium">
            {examples.map((example) => (
                <li key={example.name} className="m-2">
                    <Example {...example} />
                </li>
            ))}
        </ul>
    );
}
