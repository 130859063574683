import { ArrowPathIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useMemo, useState } from "react";
import classnames from "tailwindcss-classnames";
import { createBot, fetchAllBots, updateBot } from "../api/botApi";
import { formatDate } from "../store/user";

const EDIT_MODE_NONE = 0;
const EDIT_MODE_CREATE = 1;
const EDIT_MODE_UPDATE = 2;

function convertToCamelCase(obj) {
    let newObj = Array.isArray(obj) ? [] : {};
    for (let key in obj) {
        let value = obj[key];
        let newKey = key
            .split("_")
            .map((word, index) => word.charAt(0).toUpperCase() + word.slice(1))
            .join("");
        if (value && typeof value === "object") {
            value = convertToCamelCase(value);
        }
        newObj[newKey] = value;
    }
    return newObj;
}

function TextItem(props) {
    const { title, content } = props;
    return (
        <p>
            <span className="mr-2 select-none text-light-5">{title}:</span>{" "}
            {content}
        </p>
    );
}

function BotCard(props) {
    const { bot } = props;

    const expiredAt = useMemo(() => {
        const ts = bot?.Info?.Data?.Subscription?.ExpiredAt;
        const t = new Date(ts * 1000);
        return formatDate(t);
    }, [props]);

    const updateTime = useMemo(() => {
        const ts = bot?.Info?.UpdateTime;
        const t = new Date(ts * 1000);
        return formatDate(t);
    }, [props]);

    return (
        <div className="rounded border-[1px] border-slate-100 bg-white p-5 text-sm shadow-md">
            <div className="grid grid-cols-1 justify-between gap-1 sm:grid-cols-2 lg:grid-cols-3 ">
                <TextItem title="名称" content={bot.Username} />
                <TextItem title="编号" content={bot.ID} />
                <TextItem title="创建时间" content={bot.CreatedAt} />
                {bot.Info && (
                    <>
                        <TextItem
                            title="付费类型"
                            content={bot.Info.Data.Subscription.Type}
                        />
                        <TextItem
                            title="付费方式"
                            content={bot.Info.Data.Subscription.Duration}
                        />
                        <TextItem title="到期时间" content={expiredAt} />
                        <TextItem
                            title="当前模式"
                            content={bot.Info.Data.JobMode}
                        />
                        <TextItem
                            title="剩余Fast时间"
                            content={bot.Info.Data.FastTimeRemaining}
                        />
                        <TextItem
                            title="总用量"
                            content={bot.Info.Data.LifetimeUsage}
                        />
                        <TextItem
                            title="慢速模式用量"
                            content={bot.Info.Data.RelaxedUsage}
                        />
                        <TextItem
                            title="可见模式"
                            content={bot.Info.Data.VisibilityMode}
                        />

                        <TextItem
                            title="快速队列"
                            content={bot.Info.Data.QueuedJobsFast}
                        />
                        <TextItem
                            title="慢速队列"
                            content={bot.Info.Data.QueuedJobsRelax}
                        />
                        <TextItem
                            title="当前任务"
                            content={bot.Info.Data.RunningJobs}
                        />
                        <TextItem title="更新时间" content={updateTime} />
                    </>
                )}
            </div>
        </div>
    );
}

export default function BotsPage() {
    const [username, setUsername] = useState("");
    const [mode, setMode] = useState(0);
    const [loading, setLoading] = useState(false);

    const [bots, setBots] = useState([]);

    useEffect(() => {
        updateBots();
    }, []);

    const updateBots = () => {
        fetchAllBots().then(setBots);
    };

    const handleSumbit = async (event) => {
        event.preventDefault();
        setLoading(true);
        if (mode === EDIT_MODE_CREATE) {
            await createBot({ Username: username });
            await fetchAllBots().then(setBots);
        } else if (mode === EDIT_MODE_UPDATE) {
            await updateBot({ Username: username });
            await fetchAllBots().then(setBots);
        }

        setMode(EDIT_MODE_NONE);
        setLoading(false);
    };

    const handleReset = () => {
        setUsername("");
        setMode(EDIT_MODE_NONE);
    };

    const loadIcon = classnames("w-5 h-5 animate-spin stroke-gray-500", {
        hidden: !loading,
    });

    return (
        <div className="flex w-full flex-col space-y-8 p-2.5">
            <div className="flex flex-row justify-between">
                <h2 className="text-xl font-bold text-slate-900 dark:text-white">
                    Bots列表
                </h2>
                <div className="flex space-x-4">
                    <button
                        onClick={() => setMode(EDIT_MODE_CREATE)}
                        disabled={mode !== EDIT_MODE_NONE || loading}
                        className="rounded bg-emerald-500 px-3 py-2 text-sm font-medium text-white"
                    >
                        添加bot
                    </button>

                    <button
                        onClick={updateBots}
                        className="flex w-28 items-center justify-center rounded bg-pink-500 px-3 py-2 text-sm font-medium text-white"
                    >
                        <ArrowPathIcon className="h-4 w-4" />
                        刷新列表
                    </button>
                </div>
            </div>

            {mode !== EDIT_MODE_NONE && (
                <div className="">
                    <form className="w-full" onSubmit={handleSumbit}>
                        <label className="block">
                            <span className="block text-sm font-medium text-slate-700 after:ml-0.5 after:text-red-500 after:content-['*']">
                                {" "}
                                用户名：
                            </span>
                            <input
                                type="text"
                                className="mt-1 w-full rounded-md border border-slate-300 bg-white px-3 py-2 placeholder-slate-400 shadow-sm focus:border-sky-500  focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm"
                                placeholder="输入bot账号对应的用户名"
                                value={username}
                                required
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </label>

                        <div className="flex w-full flex-row items-center justify-end space-x-4 py-3">
                            <ArrowPathIcon className={loadIcon} />
                            <button
                                disabled={loading}
                                className="w-20 rounded bg-light-4 px-3 py-2 text-sm font-medium text-white shadow-md"
                                type="button"
                                onClick={handleReset}
                            >
                                取消
                            </button>
                            <button
                                disabled={loading}
                                className=" w-20 rounded bg-pink-600 px-3 py-2 text-sm font-medium text-white shadow-md"
                                type="sumbit"
                            >
                                保存
                            </button>
                        </div>
                    </form>
                </div>
            )}

            <ul className="space-y-6">
                {bots.map((bot) => (
                    <li key={bot.ID}>
                        <BotCard bot={convertToCamelCase(bot)} />
                    </li>
                ))}
            </ul>
        </div>
    );
}
