import React, { useState, useRef } from "react";

import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";

import "react-image-crop/dist/ReactCrop.css";
import { ArrowPathIcon } from "@heroicons/react/24/solid";

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: "%",
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight
        ),
        mediaWidth,
        mediaHeight
    );
}

const DEFAULT_AR = 1 / 1;

export default function ImageCrop({
    imgSrc = "https://afterjourney.ai3.design/4449809b2d0b404c0fbefede63f99f7d.jpg",
    onCrop = () => {},
}) {
    const previewCanvasRef = useRef(null);
    const imgRef = useRef(null);
    const hiddenAnchorRef = useRef(null);
    const blobUrlRef = useRef("");
    const [crop, setCrop] = useState();
    const [completedCrop, setCompletedCrop] = useState();
    const [scale, setScale] = useState(1);
    // const [rotate, setRotate] = useState(0)
    const [aspect, setAspect] = useState(DEFAULT_AR);
    const [loading, setLoading] = useState(false);

    function onImageLoad(e) {
        if (aspect) {
            const { width, height } = e.currentTarget;
            setCrop(centerAspectCrop(width, height, aspect));
        }
    }

    async function onCropClick() {
        setLoading(true);
        try {
            await onCrop?.(crop);
        } finally {
            setLoading(false);
        }
    }

    function onChange(pxCrop, percentCrop) {
        setCrop(percentCrop);
    }

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale
                );
            }
        },
        100,
        [completedCrop, scale]
    );

    function handleToggleAspectClick() {
        if (aspect) {
            setAspect(undefined);
        } else if (imgRef.current) {
            const { width, height } = imgRef.current;
            setAspect(16 / 9);
            setCrop(centerAspectCrop(width, height, DEFAULT_AR));
        }
    }

    return (
        <div className="App">
            <div className="flex space-x-1">
                {!!imgSrc && (
                    <ReactCrop
                        crop={crop}
                        onChange={onChange}
                        onComplete={(c) => setCompletedCrop(c)}
                        aspect={aspect}
                    >
                        <img
                            ref={imgRef}
                            alt="Crop me"
                            src={imgSrc}
                            style={{ transform: `scale(${scale})` }}
                            onLoad={onImageLoad}
                        />
                    </ReactCrop>
                )}
                <div className="flex flex-col items-end space-y-1">
                    <div className="Crop-Controls w-full">
                        {/* 
            <div>
              <label htmlFor="scale-input">缩放: </label>
              <input
                id="scale-input"
                type="number"
                step="0.1"
                value={scale}
                disabled={!imgSrc}
                onChange={(e) => setScale(Number(e.target.value))}
              />
            </div> */}
                        {/* <div>
              <label htmlFor="rotate-input">旋转: </label>
              <input
                id="rotate-input"
                type="number"
                value={rotate}
                disabled={!imgSrc}
                onChange={(e) =>
                  setRotate(Math.min(180, Math.max(-180, Number(e.target.value))))
                }
              />
            </div> */}
                        <div>
                            <button onClick={handleToggleAspectClick}>
                                比例锁定 {aspect ? "关" : "开"}
                            </button>
                        </div>
                    </div>
                    {!!completedCrop && (
                        <>
                            <div>
                                <canvas
                                    ref={previewCanvasRef}
                                    style={{
                                        objectFit: "contain",
                                        width: 300,
                                        height:
                                            (300 * completedCrop.height) /
                                            completedCrop.width,
                                    }}
                                />
                            </div>
                            <div>
                                <button
                                    className="inline-flex h-[35px] items-center justify-center rounded-[4px] bg-indigo10 px-[25px] text-sm font-medium leading-none text-white outline-none focus:shadow-[0_0_0_2px] focus:shadow-red7 hover:bg-indigo11"
                                    onClick={onCropClick}
                                >
                                    {loading ? (
                                        <ArrowPathIcon className="stroke-white-500 h-4 w-4 animate-spin motion-reduce:hidden" />
                                    ) : (
                                        "确定"
                                    )}
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
