import React from "react";
import SideBar from "../../SideBar";

export default function DashboardLayout(props) {
    return (
        <div className="flex w-screen flex-row bg-light-primary dark:bg-black-primary ">
            <SideBar />
            {props.children}
        </div>
    );
}
