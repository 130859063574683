import { ArrowPathIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import classnames from "tailwindcss-classnames";
import { queryUser, saveUser } from "../api/userApi";
import {
    SUB_MODE_COUNT,
    SUB_MODE_DURATION_COUNT,
    SUB_MODE_DURATION_INFINITE,
} from "../store/user";
import dayjs, { Dayjs } from "dayjs";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { toast } from "react-toastify";
import * as Switch from "@radix-ui/react-switch";

const subModes = [
    {
        name: "有效期内无限次使用",
        value: SUB_MODE_DURATION_INFINITE,
    },
    {
        name: "有效期期内有限次使用",
        value: SUB_MODE_DURATION_COUNT,
    },
    {
        name: "永久有限次使用",
        value: SUB_MODE_COUNT,
    },
];

const loadIcon = (loading) =>
    classnames("w-4 h-4 animate-spin stroke-gray-500 text-white", {
        hidden: !loading,
    });

function SessionForm(props) {
    const { formData = {}, onChange = () => {} } = props;
    const [loading, setLoading] = useState(false);

    const handleChange = (field) => (e) => {
        onChange({ ...formData, [field]: e.target.value });
    };

    const handleFastModeChange = (checked) => {
        onChange({ ...formData, fast_mode: checked });
    };

    const handleDateTimeChange = (field) => (value) => {
        onChange({
            ...formData,
            [field]: value.format("YYYY-MM-DDTHH:mm:ssZ"),
        });
    };

    const handleNumberChange = (field) => (e) => {
        onChange({ ...formData, [field]: Number(e.target.value) || 0 });
    };

    const handleSumbit = (e) => {
        e.preventDefault();
        props.onSubmit?.(formData);
    };

    const handleReset = () => {
        props.onCancel?.();
    };

    return (
        <form className="flex flex-col space-y-4" onSubmit={handleSumbit}>
            <label className="block">
                <span className="block text-sm font-medium text-slate-700 after:ml-0.5 after:text-red-500 after:content-['*']">
                    Session code:
                </span>
                <input
                    type="text"
                    className="mt-1 w-full rounded-md border border-slate-300 bg-white px-3 py-2 placeholder-slate-400 shadow-sm focus:border-sky-500  focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm"
                    placeholder="创建时session code将会自动生成"
                    value={formData.account_id}
                    required
                    disabled
                    onChange={handleChange("account_id")}
                />
            </label>

            <label className="block">
                <span className="block text-sm font-medium text-slate-700 after:ml-0.5 after:text-red-500 after:content-['*']">
                    订阅方式：
                </span>
                <div className="flex flex-col">
                    {subModes.map((mode) => (
                        <label key={mode.value}>
                            <input
                                type="radio"
                                value={mode.value}
                                checked={formData.sub_mode == mode.value}
                                onChange={handleNumberChange("sub_mode")}
                            />{" "}
                            {mode.name}
                        </label>
                    ))}
                </div>
            </label>

            <label className="block">
                <span className="block text-sm font-medium text-slate-700 after:ml-0.5 after:text-red-500 after:content-['*']">
                    到期时间:
                </span>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                        components={["DateTimePicker", "DateTimePicker"]}
                    >
                        <DateTimePicker
                            views={[
                                "year",
                                "month",
                                "day",
                                "hours",
                                "minutes",
                                "seconds",
                            ]}
                            value={dayjs(formData.expired_at)}
                            onChange={handleDateTimeChange("expired_at")}
                        />
                    </DemoContainer>
                </LocalizationProvider>
            </label>

            <label className="block">
                <span className="block text-sm font-medium text-slate-700 after:ml-0.5 after:text-red-500 after:content-['*']">
                    剩余次数:
                </span>
                <input
                    type="number"
                    value={formData.count}
                    onChange={handleNumberChange("count")}
                />
            </label>

            <label className="block">
                <span className="block text-sm font-medium text-slate-700 after:ml-0.5 after:text-red-500 after:content-['*']">
                    快速作图:
                </span>
                <Switch.Root
                    className="h-[20px] w-[52px] rounded-full bg-pink-200 data-[state=checked]:bg-pink-400"
                    checked={formData.fast_mode}
                    onCheckedChange={handleFastModeChange}
                >
                    <Switch.Thumb className="block h-[18px] w-[18px] translate-x-0.5 rounded-full bg-white transition-transform duration-100 will-change-transform data-[state=checked]:translate-x-[32px]"></Switch.Thumb>
                </Switch.Root>
            </label>

            <div className="flex w-full flex-row items-center justify-end space-x-4 py-3">
                <button
                    disabled={loading}
                    className="w-20 rounded bg-light-4 px-3 py-2 text-sm font-medium text-white shadow-md"
                    type="button"
                    onClick={handleReset}
                >
                    取消
                </button>
                <button
                    disabled={loading}
                    className=" w-20 rounded bg-pink-600 px-3 py-2 text-sm font-medium text-white shadow-md"
                    type="sumbit"
                >
                    <ArrowPathIcon className={loadIcon(loading)} />
                    {formData.account_id ? "保存修改" : "创建"}
                </button>
            </div>
        </form>
    );
}

export default function SessionPage() {
    const [session, setSession] = useState({});
    const [showForm, setShowForm] = useState(false);

    const [sessionId, setSessionId] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSearch = async (event) => {
        event.preventDefault();
        setLoading(true);
        setShowForm(false);
        try {
            const res = await queryUser(sessionId);
            setSession(res);
            setShowForm(res);
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        setShowForm(false);
        setSessionId("");
    };

    const handleSubmit = async (data) => {
        const res = await saveUser(data);
        setSession(res);
        toast.success("保存成功");
    };

    const handleCreate = () => {
        setSession({
            account_id: "",
            sub_mode: SUB_MODE_DURATION_INFINITE,
            expired_at: dayjs().add(30, "day").format("YYYY-MM-DDTHH:mm:ssZ"),
            count: 0,
        });
        setShowForm(true);
    };

    return (
        <div className="w-full space-y-6 p-2.5">
            <form className="flex w-full space-x-4" onSubmit={handleSearch}>
                <input
                    type="text"
                    className="mt-1 w-full rounded-md border border-slate-300 bg-white px-3 py-2 placeholder-slate-400 shadow-sm focus:border-sky-500  focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm"
                    placeholder="输入想要查找的session code"
                    value={sessionId}
                    required
                    onChange={(e) => setSessionId(e.target.value)}
                />

                <button
                    disabled={loading}
                    className="flex w-32 items-center justify-center rounded bg-green-600 px-3 py-1 text-sm font-medium text-white shadow-md"
                    type="sumbit"
                >
                    <ArrowPathIcon className={loadIcon(loading)} />
                    搜索session
                </button>
            </form>

            <button
                className="flex w-32 items-center justify-center rounded bg-pink-600 px-3 py-1 text-sm font-medium text-white shadow-md"
                onClick={handleCreate}
            >
                创建session
            </button>

            {showForm && (
                <SessionForm
                    formData={session}
                    onChange={setSession}
                    onCancel={handleCancel}
                    onSubmit={handleSubmit}
                />
            )}
        </div>
    );
}
