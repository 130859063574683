import { create } from "zustand";
import { fetchImages } from "../api/imageApi";

export const useImagesStore = create((set) => ({
    images: [],
    refreshImages: async () => {
        try {
            const resp = await fetchImages();
            set({ images: resp });
        } catch (e) {}
    },
}));
