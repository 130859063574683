import axios from "axios";
import axiosInstance from "./axiosInstance";

export const fetchImageToken = async (data) => {
    try {
        const response = await axiosInstance.post("/user/image/token", data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const removeImageBg = async (data) => {
    try {
        const response = await axiosInstance.post("/user/image/removebg", data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const uploadImageQiniu = async (host, data) => {
    const { file, token,key } = data;
    try {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("token", token);
        formData.append("key", key)

        const response = await axios.post(`https://${host}`, formData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const uploadImageS3 = async (preSignedUrl, data) => {
    const { file, token } = data;
    try {
        await axios.put(preSignedUrl, file, {
            headers: {
                "Content-Type": file.type,
            },
        });
    } catch (error) {
        throw error;
    }
};

export const recordImage = async (image) => {
    try {
        const response = await axiosInstance.post("/user/image/record", image);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchImages = async () => {
    try {
        const response = await axiosInstance.get("/user/images");
        return response.data;
    } catch (error) {
        throw error;
    }
};
