import React from "react";
import PreserveQueryLink from "../components/link/PreserveQueryLink";

export default function Home() {
    return (
        <div className="flex h-screen w-screen items-center bg-black text-center">
            <PreserveQueryLink
                className="m-auto rounded p-4 text-2xl text-white ring-pink-500 hover:animate-pulse hover:ring-2 hover:after:content-['>']"
                href="/midjourney"
            >
                体验 Midjourney 🤖
            </PreserveQueryLink>
        </div>
    );
}
