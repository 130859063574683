import { PlusCircleIcon } from "@heroicons/react/24/solid";
import {
    LockClosedIcon,
    LockOpenIcon,
    TrashIcon,
} from "@heroicons/react/24/solid";
import React, { useCallback, useEffect, useRef, useState } from "react";
import classnames from "tailwindcss-classnames";
import { useCommandStore } from "../../store/command";
import { usePromptStore } from "../../store/prompt";
import ImageSkeleton from "../ImageSkeleton";
import Image from "../ImageSkeleton";
import { useUserStore } from "../../store/user";
import Examples from "./Examples";
import { ArrowPathIcon } from "@heroicons/react/24/outline";

function TextInput(props) {
    const { value = "", onSubmit, onChange } = props;
    const editableRef = useRef();
    const [content, setContent] = useState(value);
    const writeable = useUserStore((state) => state.writeable);

    useEffect(() => {
        if (editableRef.current) {
            editableRef.current.innerHTML = value;
            adjustInputHeight();
        }
    }, [value]);

    const handleInput = () => {
        const newContent = editableRef.current?.textContent;
        setContent(newContent);
        adjustInputHeight();
        onChange?.(newContent);
    };

    const handleKeyDown = useCallback(
        (event) => {
            if (event.key == "Enter") {
                event.preventDefault();

                setContent("");
                adjustInputHeight();
                editableRef.current.innerHTML = "";
                if (content.trim().length !== 0) {
                    onSubmit?.(content);
                }
            }
        },
        [setContent, content]
    );

    const adjustInputHeight = () => {
        const input = editableRef.current;
        input.style.height = "auto";
        input.style.height = "${input.scrollHeight}px";
    };

    const handlePaste = (event) => {
        event.preventDefault();
        const text = event.clipboardData.getData("text/plain");
        document.execCommand("insertHTML", false, text);
    };

    return (
        <div className="flex flex-col">
            {!writeable && <Examples />}
            <div
                ref={editableRef}
                contentEditable={writeable}
                onInput={handleInput}
                onKeyDown={handleKeyDown}
                onPaste={handlePaste}
                className="h-auto w-full resize-none overflow-hidden rounded-lg border border-none border-gray-300 p-2 text-slate-950 empty:before:text-gray-500 empty:before:content-[attr(data-placeholder)] focus:outline-none dark:text-white"
                data-placeholder="> 直接输入{ 英文 }来描述你要生成的东西, 比如：a cute cat"
            ></div>
        </div>
    );
}

export default function PromptInput() {
    const {
        images,
        locked,
        triggerLock,
        clearImages,
        addOrRemoveImage,
        settings,
    } = usePromptStore();
    const { sendImagine, sendDescribe, sendBlend, fetchHistory } =
        useCommandStore();
    const [buttonLoading, setButtonLoading] = useState(false);

    const promptImagesBar = classnames(
        "bg-second",
        "rounded",
        "space-x-6",
        "flex",
        "flex-row",
        "border-b-[1px]",
        "border-light-primary",
        "dark:border-black-primary",
        "px-5",
        "py-2",
        {
            hidden: !images || images.length == 0,
        }
    );

    const handleSubmit = useCallback(
        (text) => {
            sendImagine(text, images, settings).then(fetchHistory);
            if (!locked) {
                clearImages();
            }
        },
        [locked, sendImagine, images, settings]
    );

    const handleDescribe = (image) => async () => {
        setButtonLoading(true);
        try {
            await sendDescribe(image);
            await fetchHistory();
            clearImages();
        } finally {
            setButtonLoading(false);
        }
    };

    const handleBlend = (images) => async () => {
        setButtonLoading(true);
        try {
            await sendBlend(images);
            await fetchHistory();
            clearImages();
        } finally {
            setButtonLoading(false);
        }
    };

    return (
        <div className="bg-primary h-auto w-full rounded bg-light-6 shadow-lg dark:bg-black-6">
            <div className={promptImagesBar}>
                <div className="flex w-10 flex-col items-center justify-around stroke-light-5 dark:stroke-white">
                    {locked ? (
                        <LockClosedIcon
                            onClick={triggerLock}
                            className="h-5 w-5 cursor-pointer"
                            fill="none"
                        />
                    ) : (
                        <LockOpenIcon
                            onClick={triggerLock}
                            fill="none"
                            className="h-5 w-5 cursor-pointer"
                        />
                    )}
                    <TrashIcon
                        onClick={clearImages}
                        fill="none"
                        className="h-5 w-5 cursor-pointer"
                    />
                </div>

                <div className="grid min-h-[70px] w-full grow grid-cols-4 items-center gap-1 lg:grid-cols-6 xl:grid-cols-12 ">
                    {images.map((image) => (
                        <div className="text-center" key={image.Image.Url}>
                            <ImageSkeleton
                                key={image.Image.Url}
                                className="aspect-auto h-auto w-full cursor-pointer rounded"
                                src={image.Image.Url}
                                onClick={() => addOrRemoveImage(image)}
                            />
                        </div>
                    ))}

                    {images.length === 1 && (
                        <button
                            className="flex min-h-[25px] items-center justify-center text-ellipsis rounded bg-black-6 px-2 py-1 leading-3 text-white dark:bg-light-5 dark:text-white"
                            onClick={handleDescribe(images[0])}
                        >
                            {buttonLoading && (
                                <ArrowPathIcon className="h-4 w-4 animate-spin" />
                            )}{" "}
                            <span>描述</span>
                        </button>
                    )}

                    {images.length > 1 && (
                        <button
                            className="flex min-h-[25px] items-center justify-center text-ellipsis rounded bg-black-6 px-2 py-1 leading-3 text-white dark:bg-light-5 dark:text-white"
                            onClick={handleBlend(images)}
                        >
                            {buttonLoading && (
                                <ArrowPathIcon className="h-4 w-4 animate-spin" />
                            )}{" "}
                            <span>融合</span>
                        </button>
                    )}
                </div>
            </div>

            <div className="rounde flex items-center px-4 py-1">
                {/* <button className="rounded-full">
                    <PlusCircleIcon className="h-6 w-6 text-black text-opacity-70 hover:text-opacity-90" />
                </button> */}
                <TextInput onSubmit={handleSubmit} />
            </div>
        </div>
    );
}
