import React, { useEffect, useRef, useState } from "react";
import { useCommandStore } from "../../store/command";
import { useUserStore } from "../../store/user";
import Message from "./components/Message";

export default function MessagesBox() {
    const boxRef = useRef();
    const { updatedAt, history, fetchHistory } = useCommandStore();
    const writeable = useUserStore((state) => state.writeable);

    const scrollToBottom = () => {
        const el = boxRef?.current;
        if (el) {
            el.scrollTop = el.scrollHeight;
        }
    };

    useEffect(() => {
        fetchHistory();
    }, []);

    useEffect(() => {
        setTimeout(scrollToBottom, 500);
    }, [updatedAt]);

    useEffect(() => {
        if (!writeable) {
            return;
        }

        let i = setInterval(fetchHistory, 3000);
        return () => clearInterval(i);
    }, [fetchHistory, writeable]);

    return (
        <div
            ref={boxRef}
            className="h-full grow overflow-y-auto scrollbar-thin scrollbar-track-light-6 scrollbar-thumb-light-5 dark:scrollbar-track-black-6 dark:scrollbar-thumb-black "
        >
            <div className="space-y flex w-11/12 flex-col space-y-2.5">
                {history.map((msg, i) => {
                    return (
                        <div key={msg.id} className="">
                            <Message message={msg} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
