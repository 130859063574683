import axiosInstance from "./axiosInstance";

export const fetchSession = async () => {
    try {
        const response = await axiosInstance.get("/user/profile");
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const queryUser = async (userId) => {
    try {
        const response = await axiosInstance.get(`/admin/user/${userId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const saveUser = async (user) => {
    try {
        const response = await axiosInstance.post(`/admin/user`, user);
        return response.data;
    } catch (error) {
        throw error;
    }
};
