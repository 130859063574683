import React, { useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";

export default function RemixPrompt({
    open,
    onOpenChange = () => {},
    defaultPrompt,
    onOk = () => {},
    onCancel = () => {},
}) {
    const [prompt, setPrompt] = useState(defaultPrompt);

    const handleCancel = () => {
        onCancel();
        setPrompt(defaultPrompt);
    };

    const handleOk = () => {
        onOk(prompt);
        setPrompt(defaultPrompt);
    };

    return (
        <Dialog.Root open={open} onOpenChange={onOpenChange}>
            <Dialog.Portal>
                <Dialog.Overlay className="fixed inset-0 bg-blackA9 data-[state=open]:animate-overlayShow" />
                <Dialog.Content className="fixed left-[50%] top-[50%] max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none data-[state=open]:animate-contentShow">
                    <Dialog.Title className="m-0 text-[17px] font-medium text-mauve12">
                        Remix Prompt
                    </Dialog.Title>
                    <Dialog.Description className="mb-5 mt-[10px] text-[15px] leading-normal text-mauve11">
                        可根据需要提示词，或者直接使用原提示词
                    </Dialog.Description>

                    <div>
                        <textarea
                            name=""
                            id=""
                            value={prompt}
                            onChange={(e) => setPrompt(e.target.value)}
                            cols="30"
                            rows="5"
                            placeholder="输入新的提示语"
                            minLength={1}
                            maxLength={4000}
                            className="text-md w-full resize-none bg-gray4 p-[10px] font-medium outline-none"
                        ></textarea>
                    </div>

                    <div className="flex justify-end gap-[25px]">
                        <Dialog.Close asChild>
                            <button
                                onClick={handleCancel}
                                className="inline-flex h-[35px] items-center	 justify-center rounded-[4px] px-[15px] text-sm font-medium text-mauve11 outline-none hover:underline "
                            >
                                取消
                            </button>
                        </Dialog.Close>
                        <Dialog.Close asChild>
                            <button
                                onClick={handleOk}
                                className="inline-flex h-[35px] items-center justify-center rounded-[4px] bg-indigo10 px-[25px] text-sm font-medium leading-none text-white outline-none focus:shadow-[0_0_0_2px] focus:shadow-red7 hover:bg-indigo11"
                            >
                                提交
                            </button>
                        </Dialog.Close>
                    </div>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
}
