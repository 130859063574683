import {
    ArrowPathIcon,
    ExclamationCircleIcon,
    PhotoIcon,
    PlusCircleIcon,
} from "@heroicons/react/24/solid";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    fetchImages,
    fetchImageToken,
    recordImage,
    uploadImageQiniu,
    uploadImageS3,
} from "../../../../api/imageApi";
import CryptoJS from "crypto-js";
import { usePromptStore } from "../../../../store/prompt";
import ImageSkeleton from "../../../ImageSkeleton";
import { HeartIcon } from "@heroicons/react/24/solid";
import { toast } from "react-toastify";
import { useImagesStore } from "../../../../store/images";

function getFileHash(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            // 处理文件内容
            const dataUrl = event.target.result;
            // 读取文件哈希值
            const hasher = new FileReader();
            hasher.onload = (event) => {
                const data = event.target.result;
                const md5 = CryptoJS.MD5(
                    CryptoJS.lib.WordArray.create(data)
                ).toString();
                resolve(md5);
            };
            hasher.onerror = (event) => {
                reject(event.target.error);
            };
            hasher.readAsArrayBuffer(file);
        };
        reader.onerror = (event) => {
            reject(event.target.error);
        };
        reader.readAsDataURL(file);
    });
}

function UploadBox(props) {
    const { onUpload, loading, onFileChange, file } = props;

    const [image, setImage] = useState("");

    const handleFile = (file) => {
        const type = file.type;
        if (type && type.startsWith("image/")) {
            onFileChange(file);
        }
    };

    useEffect(() => {
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setImage("");
        }
    }, [file]);

    const handleDrop = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        let imageUrl = e.dataTransfer.getData("text/plain");

        if (imageUrl) {
            // Fetch the image as a Blob
            const response = await fetch(imageUrl);
            const imageBlob = await response.blob();
            // Create a File object from the Blob
            const imageFile = new File([imageBlob], "image.png", {
                type: "image/png",
            });
            handleFile(imageFile);
        } else if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            const file = e.dataTransfer.files[0];

            handleFile(file);
        } else {
            const item = e.dataTransfer.items[0];
            if (item.kind === "string" && item.type === "text/uri-list") {
                item.getAsString(async (imageUrl) => {
                    console.log(imageUrl, "url");
                    // Fetch the image as a Blob
                    const response = await fetch(imageUrl);
                    const imageBlob = await response.blob();
                    // Create a File object from the Blob
                    const imageFile = new File([imageBlob], "image.png", {
                        type: "image/png",
                    });
                    handleFile(imageFile);
                });
                return;
            } else {
                toast.error("Please drop an image.");
            }
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleFileInputChange = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];

            handleFile(file);
        }

        e.target.value = null;
    };

    const handleUploadClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onUpload?.(file);
    };

    return (
        <>
            <form
                className="mb-6 rounded-md border border-dashed border-slate-700 dark:border-slate-400"
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onSubmit={(e) => e.preventDefault()}
            >
                <label
                    htmlFor="file-input"
                    className="flex cursor-pointer flex-col p-10"
                >
                    <input
                        id="file-input"
                        className="hidden"
                        accept="image/*"
                        type="file"
                        disabled={loading}
                        onChange={handleFileInputChange}
                    />
                    {!image && (
                        <div className="flex w-full items-center justify-center space-x-5 text-slate-950 dark:text-white">
                            <PlusCircleIcon
                                className="h-6 w-6 stroke-slate-950 dark:stroke-white"
                                fill="none"
                            ></PlusCircleIcon>
                            <p className="font-bold">选择或者拖入图片</p>
                        </div>
                    )}
                    {image && (
                        <div className="flex flex-row items-center justify-center space-x-5">
                            <img className="h-20" src={image} alt="uploaded" />
                            {loading ? (
                                <ArrowPathIcon className="h-4 w-4 animate-spin stroke-pink-500 motion-reduce:hidden" />
                            ) : (
                                <button
                                    onClick={handleUploadClick}
                                    className="flex space-x-1 rounded-md border border-slate-300 bg-white px-4 py-2 text-sm font-semibold text-slate-700 shadow-sm ring-pink-500 ring-offset-4 ring-offset-slate-50 hover:ring-2 "
                                >
                                    Upload
                                </button>
                            )}
                        </div>
                    )}
                </label>
            </form>
        </>
    );
}

export default function ImageUploads(props) {
    const { addOrRemoveImage } = usePromptStore();
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);
    const { images, refreshImages } = useImagesStore();

    const handleImageClick = (image) => () => {
        addOrRemoveImage(image);
    };

    useEffect(() => {
        refreshImages();
    }, []);

    const handleFileChange = (file) => {
        setFile(file);
    };

    const handleUpload = async (selectedFile) => {
        setLoading(true);

        try {
            const md5 = await getFileHash(selectedFile);
            const resp = await fetchImageToken({
                hash: md5,
                type: selectedFile.type,
                size: selectedFile.size,
                name: selectedFile.name,
            });

            let image = resp?.image;

            if (resp?.token?.storage === "qiniu") {
                // 重复上传
                if (image === null) {
                    image = await uploadImageQiniu(resp?.token?.up_hosts[0], {
                        file: selectedFile,
                        token: resp?.token.token,
                        key: resp?.token.key,
                    });
                }
            } else if (resp?.token?.storage === "r2") {
                // 重复上传
                if (image === null) {
                    console.log(resp?.token?.url);
                    await uploadImageS3(resp?.token?.url, {
                        file: selectedFile,
                        token: resp?.token.token,
                    });
                    image = {
                        storage: resp?.token?.storage,
                        bucket: resp?.token?.bucket,
                        key: resp?.token?.key,
                        name: selectedFile.name,
                        fsize: selectedFile.size,
                        mime: selectedFile.type,
                    };
                }
            }
            console.log(image);
            await recordImage(image);
            refreshImages();
        } finally {
            setLoading(false);
            setFile(null);
        }
    };

    return (
        <div className="flex h-full flex-col space-y-8">
            <div className="flex flex-row items-center text-xl">
                <PhotoIcon className="mr-3 h-5 w-5 text-indigo-500" />
                <p className="font-bold text-slate-950 dark:text-white">
                    图片上传
                </p>
            </div>

            <div className="flex flex-row items-center rounded-md bg-light-6 p-4 text-sm dark:bg-black-6">
                <ExclamationCircleIcon
                    className="mr-3 h-6 w-6 shrink-0 stroke-orange-400"
                    fill="none"
                />
                <p className="text-orange-400">
                    尽管其他用户无法查看您所上传的图像，但您仍应谨慎对待上传内容！确保您遵循相关法规和政策，并避免分享敏感或不当信息。
                </p>
            </div>

            <UploadBox
                onFileChange={handleFileChange}
                onUpload={handleUpload}
                loading={loading}
                file={file}
            />

            <div className="flex flex-row items-center rounded-md bg-light-6 p-4 text-sm dark:bg-black-6">
                <HeartIcon className="mr-3 h-6 w-6 shrink-0 text-red-400" />
                <p className="text-red-400">
                    1. 可以直接将生成的图片拖到上传框中.
                    <br />
                    2. 点击图片会激活以图生图，再次点击则取消.
                    <br />
                    3. 以图生图支持同时使用多张图片
                </p>
            </div>

            <div className="w-full columns-3 gap-0 overflow-y-auto scrollbar">
                {images &&
                    images.map((image, i) => {
                        return (
                            <ImageSkeleton
                                key={image.ImageKey}
                                className="aspect-auto w-full cursor-pointer rounded p-[2px]"
                                src={image.Image.Url}
                                onClick={handleImageClick(image)}
                            />
                        );
                    })}
            </div>
        </div>
    );
}
