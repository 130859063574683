import { Combobox, Transition } from "@headlessui/react";
import {
    BoltIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    Cog8ToothIcon,
    ExclamationCircleIcon,
    HashtagIcon,
    NoSymbolIcon,
    PhotoIcon,
    RocketLaunchIcon,
    SparklesIcon,
    Squares2X2Icon,
} from "@heroicons/react/24/outline";
import React, { useState, Fragment, useMemo } from "react";
import classnames from "tailwindcss-classnames";
import { usePromptStore } from "../../../../store/prompt";
import Select from "react-select";
import { useEffect } from "react";

function SettingItem(props) {
    const [query, setQuery] = useState("");
    const {
        icon,
        title,
        value,
        onChange,
        anyValue,
        options = [],
        className,
    } = props;

    const style = classnames(
        "flex items-center rounded bg-opacity-20 p-2",
        className
    );

    const filteredOptions = useMemo(() => {
        if (!query || query.length === 0) {
            return options;
        }

        const filtered = options.filter((opt) =>
            opt
                .toLowerCase()
                .replace(/\s+/g, "")
                .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

        if (filtered.length == 0 && anyValue) {
            return [query];
        }

        return filtered;
    }, [options, query]);

    const showOptions = filteredOptions && filteredOptions.length > 0;

    return (
        <div className={style}>
            <div className="flex grow items-center space-x-1">
                <span className="font-bold">{icon}</span>
                <span className="text-sm text-white">{title}</span>
            </div>
            <Combobox className="basis-1/2" value={value} onChange={onChange}>
                <div className="relative mt-1">
                    <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                        <Combobox.Input
                            onChange={(event) => setQuery(event.target.value)}
                            className="w-full border-none bg-transparent py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:outline-none"
                        ></Combobox.Input>
                        {showOptions && (
                            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronLeftIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                />
                            </Combobox.Button>
                        )}
                    </div>
                    {showOptions && (
                        <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                            afterLeave={() => setQuery("")}
                        >
                            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {filteredOptions.map((opt) => (
                                    <Combobox.Option
                                        className={({ active }) =>
                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                                active
                                                    ? "bg-teal-600 text-white"
                                                    : "text-gray-900"
                                            }`
                                        }
                                        key={opt}
                                        value={opt}
                                    >
                                        {opt}
                                    </Combobox.Option>
                                ))}
                            </Combobox.Options>
                        </Transition>
                    )}
                </div>
            </Combobox>
        </div>
    );
}

const versionOptions = [
    { value: "5.1", label: "MJ version 5.1" },
    { value: "5", label: "MJ version 5" },
    { value: "4", label: "MJ version 4" },
    { value: "niji5", label: "Niji version 5" },
    { value: "niji4", label: "Niji version 4" },
    { value: "1", label: "MJ version 1" },
    { value: "2", label: "MJ version 2" },
    { value: "3", label: "MJ version 3" },
    { value: "test", label: "MJ Test" },
    { value: "testp", label: "MJ Test Photo" },
];

const qualityOptions = [
    { value: "0.5", label: "Half quality" },
    { value: "1", label: "Base quality" },
    { value: "2", label: "High quality (2x cost)" },
];

const styleOptions = [
    { value: "50", label: "Style low" },
    { value: "250", label: "Style med" },
    { value: "500", label: "Style high" },
    { value: "750", label: "Style very high" },
];

const sizeOptions = [
    { value: "1:1", label: "Size 1:1" },
    { value: "2:1", label: "Size 2:1" },
    { value: "4:3", label: "Size 4:3" },
    { value: "16:9", label: "Size 16:9" },
];

export default function Settings() {
    const { settings, setSettings, resetSettings } = usePromptStore();

    const version = useMemo(
        () => versionOptions.find((opt) => opt.value === settings.Version),
        [settings.Version]
    );
    const quality = useMemo(
        () => qualityOptions.find((opt) => opt.value === settings.Quality),
        [settings.Quality]
    );
    const style = useMemo(
        () => styleOptions.find((opt) => opt.value === settings.Style),
        [settings.Style]
    );
    const size = useMemo(
        () => sizeOptions.find((opt) => opt.value === settings.Ar),
        [settings.Ar]
    );
    const stop = useMemo(() => Number(settings.Stop) || 100, [settings.Stop]);

    return (
        <div className="flex h-full flex-col space-y-8 overflow-auto p-2 scrollbar">
            <div className="flex flex-row items-center text-xl">
                <Cog8ToothIcon className="mr-3 h-5 w-5 stroke-green-500" />
                <p className="font-bold text-slate-950 dark:text-white">
                    作图配置
                </p>
            </div>

            <div className="flex flex-row items-center rounded-md bg-light-6 p-4 text-sm dark:bg-black-6">
                <ExclamationCircleIcon
                    className="mr-3 h-6 w-6 shrink-0 stroke-orange-400"
                    fill="none"
                />
                <p className="text-orange-400">
                    全局默认配置，修改之前请确保自己知道每个参数对应的影响
                </p>
            </div>

            <div className="flex grow flex-col space-y-6  overflow-auto scrollbar">
                <Select
                    value={version}
                    onChange={(v) => setSettings("Version", v?.value || "")}
                    options={versionOptions}
                    placeholder="version"
                />

                <Select
                    value={quality}
                    onChange={(v) => setSettings("Quality", v?.value || "")}
                    options={qualityOptions}
                    placeholder="图片质量"
                />

                <Select
                    value={style}
                    onChange={(v) => setSettings("Style", v?.value || "")}
                    options={styleOptions}
                    placeholder="style"
                />

                <Select
                    value={size}
                    onChange={(v) => setSettings("Ar", v?.value || "")}
                    options={sizeOptions}
                    placeholder="图片尺寸"
                />

                <label className="flex items-center space-x-4">
                    <span className="text-sm dark:text-white">
                        stop = {stop}
                    </span>
                    <input
                        type="range"
                        min="1"
                        max="100"
                        value={stop}
                        onChange={(e) =>
                            setSettings("Stop", `${e.target.value}`)
                        }
                    />
                </label>

                {/* <fieldset className="flex flex-col space-y-1 text-gray-500">
                    <legend>Flags</legend>
                    <SettingItem
                        className="bg-gray-500 bg-opacity-10"
                        icon={<BoltIcon className="h-4 w-4"></BoltIcon>}
                        title="Mode"
                        value={settings.Mode}
                        onChange={handleChange("Mode")}
                        options={["fast", "slow"]}
                    ></SettingItem>
                </fieldset> */}

                {/* <fieldset className="flex flex-col space-y-1 text-green-500">
                    <legend>Basic Settings</legend>
                    <SettingItem
                        options={["1:1", "2:1", "4:3", "16:9"]}
                        anyValue
                        value={settings.Ar}
                        onChange={handleChange("Ar")}
                        className="bg-green-500 bg-opacity-10"
                        icon={<HashtagIcon className="h-4 w-4"></HashtagIcon>}
                        title="Image Size"
                    ></SettingItem>
                </fieldset> */}

                {/* <fieldset className="flex flex-col space-y-1 text-fuchsia-500 ">
                    <legend>Extra Settings</legend>
                    <SettingItem
                        anyValue
                        value={settings.Tile}
                        onChange={handleChange("Tile")}
                        className="bg-fuchsia-500 bg-opacity-10"
                        icon={
                            <Squares2X2Icon className="h-4 w-4"></Squares2X2Icon>
                        }
                        title="Tile"
                    ></SettingItem>
                    <SettingItem
                        anyValue
                        value={settings.Chaos}
                        onChange={handleChange("Chaos")}
                        className="bg-fuchsia-500 bg-opacity-10"
                        icon={<SparklesIcon className="h-4 w-4"></SparklesIcon>}
                        title="Chaos"
                    ></SettingItem>
                </fieldset> */}

                {/* <fieldset className="flex flex-col space-y-1 text-orange-400">
                    <legend>Advanced Settings</legend>
                        <SettingItem
                            anyValue
                            value={settings.Seed}
                            onChange={handleChange("seed")}
                            className="bg-orange-500 bg-opacity-10"
                            icon={<MapIcon className="h-4 w-4"></MapIcon>}
                            title="Seed"
                        ></SettingItem>
                    <SettingItem
                        anyValue
                        value={settings.Stop}
                        onChange={handleChange("Stop")}
                        className="bg-orange-500 bg-opacity-10"
                        icon={<NoSymbolIcon className="h-4 w-4"></NoSymbolIcon>}
                        title="Stop"
                    ></SettingItem>
                    <SettingItem
                        anyValue
                        value={settings.ImageWeight}
                        onChange={handleChange("ImageWeight")}
                        className="bg-orange-500 bg-opacity-10"
                        icon={<PhotoIcon className="h-4 w-4"></PhotoIcon>}
                        title="Image Weight"
                    ></SettingItem>
                </fieldset> */}

                {/* <fieldset className="flex flex-col space-y-1 text-pink-500">
                    <legend>Template Settings</legend>
                    <SettingItem
                        anyValue
                        value={settings.Suffix}
                        onChange={handleChange("Suffix")}
                        className="bg-pink-500 bg-opacity-10"
                        icon={
                            <ChevronLeftIcon className="h-4 w-4"></ChevronLeftIcon>
                        }
                        title="Suffix"
                    ></SettingItem>
                    <SettingItem
                        anyValue
                        value={settings.Prefix}
                        onChange={handleChange("Prefix")}
                        className="bg-pink-500 bg-opacity-10"
                        icon={
                            <ChevronRightIcon className="h-4 w-4"></ChevronRightIcon>
                        }
                        title="Prefix"
                    ></SettingItem>
                </fieldset> */}
            </div>

            <div className="flex flex-row-reverse">
                <button
                    className="tex-sm w-16 rounded bg-green-500 px-3 py-1 text-white shadow-lg"
                    onClick={resetSettings}
                >
                    重置
                </button>
            </div>
        </div>
    );
}
