import React, { useState } from "react";
import { useCommandStore } from "../../../../store/command";
import { Gallery, Item } from "react-photoswipe-gallery";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import ImageSkeleton from "../../../ImageSkeleton";
import ComponentButton from "../ComponentButton";
import RemoveBgDialog from "../RemoveBg";

export default function UpscaleMessage(props) {
    const {
        message: {
            id,
            Role = "Midjourney",
            task: {
                interaction: {
                    type,
                    upscale: { prompt },
                },
            },
            response,
            status,
            Message,
        },
    } = props;

    const images = response?.progress?.images || [];
    const hasRemoveBg = images?.length > 0;

    const { sendRedo, sendVary, sendUpscale, fetchHistory } = useCommandStore();
    const [rmbgShow, setRmbgShow] = useState(false);
    const [img, setImg] = useState();

    const handleClick = () => {
        setImg(images[0]);
        setRmbgShow(true);
    };

    return (
        <div className="w-full flex-col space-y-2">
            <div className="select-text text-sm">
                <p className="text-sm break-all font-medium text-slate-700 dark:text-white md:text-lg">
                    {prompt}
                    {/* <span className="italic text-gray-400">&nbsp;{Flags}</span> */}
                </p>
            </div>

            <Gallery withDownloadButton>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-4">
                    {images.map((image) => (
                        <Item
                            cropped
                            key={image.proxy_url}
                            original={image.proxy_url}
                            thumbnaill={image.proxy_url + "?/0/w/400/q/60"}
                            width={image.width}
                            height={image.height}
                            caption={`widht=${image.width} height=${image.height}`}
                        >
                            {({ ref, open }) => (
                                <ImageSkeleton
                                    ref={ref}
                                    onClick={open}
                                    className="rounded"
                                    src={image.proxy_url}
                                />
                            )}
                        </Item>
                    ))}
                </div>
            </Gallery>

            {img && (
                <RemoveBgDialog
                    open={rmbgShow}
                    onOpenChange={setRmbgShow}
                    imgSrc={img.proxy_url}
                    imgHeight={img.height}
                    imgWidth={img.width}
                />
            )}

            {hasRemoveBg && (
                <div className="">
                    <ComponentButton
                        onClick={handleClick}
                        className="flex w-[120px] justify-center rounded bg-light-5 px-4 py-1 text-sm text-white"
                    >
                        去背景(测试)
                    </ComponentButton>
                </div>
            )}
        </div>
    );
}
