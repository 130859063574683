import React, { useState } from "react";
import {
    INTERACTION_TYPE_REROLL,
    INTERACTION_TYPE_VARY,
    useCommandStore,
} from "../../../../store/command";
import { Gallery, Item } from "react-photoswipe-gallery";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import ImageSkeleton from "../../../ImageSkeleton";
import RemixPrompt from "../RemixPrompt";
import ComponentButton from "../ComponentButton";

export default function VaryMessage(props) {
    const {
        message: {
            id,
            Role = "Midjourney",
            task: {
                interaction: {
                    type,
                    vary: { prompt },
                },
            },
            response,
            status,
            Message,
        },
    } = props;

    const images = response?.progress?.images || [];
    const hasSubCommands = response?.interactions?.length > 0;

    const { sendRedo, sendVary, sendUpscale, fetchHistory } = useCommandStore();
    const [remixOpen, setRemixOpen] = useState(false);
    const [remixFn, setRemixFn] = useState(() => {});
    const [newPrompt, setNewPrompt] = useState(prompt);

    const handleReroll = () => {
        const reroll = response?.interactions?.find(
            (interaction) => interaction.type === INTERACTION_TYPE_REROLL
        );
        if (!reroll) {
            return;
        }

        setNewPrompt(reroll?.reroll?.prompt);
        setRemixOpen(true);

        setRemixFn(() => (prompt) => {
            sendRedo(props.message, prompt).then(fetchHistory);
        });
    };

    const handleVibrate = (index) => () => {
        const vary = response?.interactions?.find(
            (interaction) =>
                interaction.type === INTERACTION_TYPE_VARY &&
                interaction.vary.index === index
        );
        if (!vary) {
            return;
        }

        setNewPrompt(vary?.vary?.prompt);
        setRemixOpen(true);
        setRemixFn(() => (prompt) => {
            sendVary(props.message, index, prompt).then(fetchHistory);
        });
    };

    const handleUpscale = (index) => () => {
        sendUpscale(props.message, index).then(fetchHistory);
    };

    return (
        <div className="w-full flex-col space-y-2">
            <div className="select-text text-sm">
                <p className="text-sm break-all font-medium text-slate-700 dark:text-white md:text-lg">
                    {prompt}
                    {/* <span className="italic text-gray-400">&nbsp;{Flags}</span> */}
                </p>
            </div>

            <Gallery withDownloadButton>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-4">
                    {images.map((image) => (
                        <Item
                            cropped
                            key={image.proxy_url}
                            original={image.proxy_url}
                            thumbnaill={image.proxy_url + "?/0/w/400/q/60"}
                            width={image.width}
                            height={image.height}
                            caption={`widht=${image.width} height=${image.height}`}
                        >
                            {({ ref, open }) => (
                                <ImageSkeleton
                                    ref={ref}
                                    onClick={open}
                                    className="rounded"
                                    src={image.proxy_url}
                                />
                            )}
                        </Item>
                    ))}
                </div>
            </Gallery>

            {remixOpen && (
                <RemixPrompt
                    open={true}
                    onOpenChange={setRemixOpen}
                    defaultPrompt={newPrompt}
                    onOk={remixFn}
                />
            )}

            {hasSubCommands && (
                <div className="grid w-80 grid-cols-5 gap-2">
                    <ComponentButton
                        onClick={handleUpscale(1)}
                        className="rounded bg-light-5 px-4 py-1 text-sm text-white"
                    >
                        U1
                    </ComponentButton>
                    <ComponentButton
                        onClick={handleUpscale(2)}
                        className="rounded bg-light-5 px-4 py-1 text-sm text-white"
                    >
                        U2
                    </ComponentButton>
                    <ComponentButton
                        onClick={handleUpscale(3)}
                        className="rounded bg-light-5 px-4 py-1 text-sm text-white"
                    >
                        U3
                    </ComponentButton>
                    <ComponentButton
                        onClick={handleUpscale(4)}
                        className="rounded bg-light-5 px-4 py-1 text-sm text-white"
                    >
                        U4
                    </ComponentButton>
                    <ComponentButton
                        className="rounded bg-light-5 px-4 py-1 text-sm text-white"
                        onClick={handleReroll}
                    >
                        <ArrowPathIcon className="m-auto h-5 w-5 bg-blue-400" />
                    </ComponentButton>
                    <ComponentButton
                        onClick={handleVibrate(1)}
                        className="rounded bg-light-5 px-4 py-1 text-sm text-white"
                    >
                        V1
                    </ComponentButton>
                    <ComponentButton
                        onClick={handleVibrate(2)}
                        className="rounded bg-light-5 px-4 py-1 text-sm text-white"
                    >
                        V2
                    </ComponentButton>
                    <ComponentButton
                        onClick={handleVibrate(3)}
                        className="rounded bg-light-5 px-4 py-1 text-sm text-white"
                    >
                        V3
                    </ComponentButton>
                    <ComponentButton
                        onClick={handleVibrate(4)}
                        className="rounded bg-light-5 px-4 py-1 text-sm text-white"
                    >
                        V4
                    </ComponentButton>
                </div>
            )}
        </div>
    );
}
