import React from "react";
import ChatBox from "../components/ChatBox";
import Toolbar from "../components/Toolbar";

export default function Midjourney() {
    return (
        <div className="flex w-full flex-row bg-light-primary dark:bg-black-primary">
            <ChatBox />
            <Toolbar />
        </div>
    );
}
