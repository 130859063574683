import {
    Cog8ToothIcon,
    FolderIcon,
    PhotoIcon,
} from "@heroicons/react/24/solid";
import React, { useCallback } from "react";
import ImageUploads from "./components/ImageUploads";
import Settings from "./components/Settings";

import "./index.css";
import {
    ToolPanelImages,
    ToolPanelSettings,
    ToolPanelWorkSpace,
    useToolBarStore,
} from "../../store/toolbar";
import classnames from "tailwindcss-classnames";

export default function Toolbar() {
    const { panel, openPanel } = useToolBarStore();
    const Panels = [
        // {
        //     name: ToolPanelWorkSpace,
        //     icon: (
        //         <FolderIcon
        //             className="m-auto h-5 w-5 stroke-orange-500"
        //             fill="none"
        //         />
        //     ),
        //     panel: <></>,
        // },
        {
            name: ToolPanelSettings,
            icon: <Cog8ToothIcon className="m-auto h-5 w-5 text-green-500" />,
            panel: <Settings />,
        },
        {
            name: ToolPanelImages,
            icon: <PhotoIcon className="m-auto h-5 w-5 text-indigo-500" />,
            panel: <ImageUploads />,
        },
    ];

    const buttonStyle = useCallback(
        (name) =>
            classnames(
                "h-12 w-full cursor-pointer bg-light-second dark:bg-black-second flex items-center shadow-lg rounded",
                { "bg-light-third dark:bg-black-third": name === panel }
            ),
        [panel]
    );
    const panelStyle = useCallback(
        (name) =>
            classnames(
                "inset-2.5 h-full transition-all duration-500 ease-in-out",
                { "translate-y-0 opacity-100": name === panel },
                { "absolute -translate-y-full opacity-0": name !== panel }
            ),
        [panel]
    );
    return (
        <div className="h-screen max-w-md shrink-0 basis-4/12 p-2.5">
            <ul className="absolute -ml-20 w-12 list-none space-y-4 bg-light-primary dark:bg-black-primary">
                {Panels.map((p) => {
                    return (
                        <li
                            key={p.name}
                            onClick={() => openPanel(p.name)}
                            className={buttonStyle(p.name)}
                        >
                            {p.icon}
                        </li>
                    );
                })}
            </ul>

            <div className="relative h-full w-full rounded bg-light-second p-3  shadow-lg dark:bg-black-second">
                {Panels.map((p) => {
                    return (
                        <div key={p.name} className={panelStyle(p.name)}>
                            {p.panel}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
