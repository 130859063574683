import { EllipsisHorizontalIcon } from "@heroicons/react/24/solid";
import React, { useState } from "react";

export default function ComponentButton({ text, onClick, children, ...props }) {
    const [loading, setLoading] = useState(false);

    const handleClick = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            onClick?.();
        }, 1500);
    };
    return (
        <button onClick={handleClick} {...props}>
            {loading ? (
                <EllipsisHorizontalIcon className="h-5 w-5 animate-ping" />
            ) : (
                <>{children}</>
            )}
        </button>
    );
}
