import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import React, { useEffect } from "react";
import {
    useUserStore,
    SUB_MODE_COUNT,
    SUB_MODE_DURATION_COUNT,
    SUB_MODE_DURATION_INFINITE,
} from "../../store/user";

const SHOP_URL =
    "https://shop286855392.taobao.com/search.htm?spm=2013.1.0.0.6ef146bfa05fYT&search=y";

// 过期时间
function ExpiredTime(props) {
    const { expiredAt = null } = props;
    return (
        <p className="flex items-center">
            <span className="text-gray-400">到期时间：</span>
            <span>{expiredAt}</span>
        </p>
    );
}

// 剩余次数
function Count(props) {
    const { count = 0 } = props;
    return (
        <p className="flex items-center">
            <span className="text-gray-400">剩余作图数：</span>
            <span>{count}</span>
        </p>
    );
}

export default function UserCard() {
    const { sessionId, subMode, expiredAt, count, isAdmin, writeable } =
        useUserStore();

    const renderStats = (subMode, expiredAt, count) => {
        switch (subMode) {
            case SUB_MODE_DURATION_INFINITE:
                return <ExpiredTime expiredAt={expiredAt} />;
            case SUB_MODE_DURATION_COUNT:
                return (
                    <>
                        <ExpiredTime expiredAt={expiredAt} />{" "}
                        <Count count={count} />
                    </>
                );
            case SUB_MODE_COUNT:
                return <Count count={count} />;
            default:
                break;
        }
    };

    return (
        <div className="flex items-center justify-center bg-light-primary dark:bg-black-primary">
            <div className="flex space-x-2 p-1 text-sm text-slate-900 dark:text-white">
                {!writeable ? (
                    <p className="flex items-center space-x-4 text-red-600">
                        <ExclamationCircleIcon
                            className="h-6 w-6 stroke-red-600"
                            fill="none"
                        />
                        {/* <a target="__blank" href={SHOP_URL}> */}
                        <span>抱歉，服务已到期，请重新购买</span>
                        {/* </a> */}
                    </p>
                ) : (
                    renderStats(subMode, expiredAt, count)
                )}
            </div>
        </div>
    );
}
